.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

link {
 color: black !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-bar { 
  background-color: white !important;
  color: black !important;
  padding: 10px !important;
}

.bottom-background-color {
  background-color: rgb(8,67,132);
}
.align-left-content {
  text-align: left;
}
header {
  /* box-shadow: rgb(8,67,132) !important; */
  /* border-bottom: rgb(8,67,132) 2px solid ; */
}

h2{
  font-weight:450;
}

a {
  text-decoration: none;
}
.css-1okebmr-indicatorSeparator {
  background-color: white !important;
}
.css-yk16xz-control {
  border: none !important;
}

.new-font {

}

@media (min-width:1503px) {
  .new-font {
    font-size: 1vw !important;
  }
}

@media (min-width: 1503px) {
  .display-medium {
    display: none;
  }
  .display-medium-md {
    display: none;
  }
  .display-large {
    display: flex;
  }
}

@media (min-width: 1303px) and (max-width: 1502.9px) {
  .display-medium {
    display: none;
  }
  .display-medium-md {
    display: flex;
  }
  .display-large {
    display: none;
  }
}

@media (min-width: 960px) and (max-width: 1302.9px) {
  .display-medium {
    display: flex;
  }
  .display-medium-md {
    display: none;
  }
  .display-large {
    display: none;
  }
}

.css-2b097c-container {
  width: 100%;
}

a {
  color: #2c75c5;
}

.app-link {
  cursor: pointer;
}